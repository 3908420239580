<template>
  <section id="tiss-health-plan">

    
    <b-form-group>
      <label for="name" class="label">
        Nome do layout
      </label>
      <b-input
        id="name"
        v-model="form.name"
        class="form-control"
      />
    </b-form-group>
  
    <b-form-group>
      <label for="description" class="label">
        Descrição <span>(opcional)</span>
      </label>
      <b-input
        id="description"
        v-model="form.description"
        class="form-control"
      />
    </b-form-group>
    
    <div class="options-wrapper">
      <b-col>
        <b-form-group>
          <label class="label">Despesa</label>
          <ItemHealthPlanSimpleSelector 
          :clinicHealthPlanId="clinicHealthPlan.id"
          :planId="null"
          :allowedTypes="['OTHER_EXPENSES']"
          v-model="item"
          />
        </b-form-group>      
      </b-col>
        <b-button 
        variant="primary" 
        @click="addExpense"
        :disabled="!item"
        class="button"
        >
        Adicionar
      </b-button>
    </div>
      <!-- <SelectOtherExpense 
        required
        label="Despesa"
        placeholder="Selecione uma despesa"
        v-model="item"
        :clinicHealthPlanId="clinicHealthPlan.id"
      /> -->

    <ExpenseModuleTable 
      :items="form.expenses"
      :copyExpense="copyExpense"
      :deleteExpense="deleteModal"
    />
    <b-row class="w-100 justify-content-end">
      <span class="tag">
        Valor total do layout: <strong>{{ getTotalValue() }}</strong>
      </span>
    </b-row>
  </section>
</template>

<script>
import tableOptions from '@/components/Tiss/Data/tables'
import unitMeasures from '@/components/Tiss/Data/unitMeasures'
import cdsOptions from '@/components/Tiss/Data/cds'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'Expenses',
  components: {
    ItemHealthPlanSimpleSelector: () => import('@items/components/ItemHealthPlanSimpleSelector'),
    ExpenseModuleTable: () => import('@/components/Tiss/components/ExpenseModuleTable'),
  },
  props: {
    clinicHealthPlan: Object,
    form: { 
      type: Object, 
      required: true
    },
  },
  computed: {
    clinicHealthPlanTableMedicine() {
      return this.clinicHealthPlan?.clinic_health_plan_tables
        .filter(clinicHealthPlanTable => clinicHealthPlanTable.sub_type === 'MEDICINE' 
        && !clinicHealthPlanTable.clinic_plan_id)[0]
    },
    clinicHealthPlanTableMaterial() {
      return this.clinicHealthPlan?.clinic_health_plan_tables
        .filter(clinicHealthPlanTable => clinicHealthPlanTable.sub_type === 'MATERIAL'
        && !clinicHealthPlanTable.clinic_plan_id)[0]
    },
    isSameMaterialMedicineTable() {
      return this.clinicHealthPlanTableMedicine?.table_id === this.clinicHealthPlanTableMaterial?.table_id
    },
  },
  data() {
    return {
      item: null,
      expenseEdit: {},
      index: 0,
      tableOptions,
      cdsOptions,
      unitMeasures,
    }
  },
  methods: {
    addExpense () {
      this.form.expenses.push({
        cd: this.item.other_expense_props.cd,
        table_name: this.item.other_expense_props.table_number,
        tuss_item_id: this.item.id,
        anvisa_registration: this.item.other_expense_props.anvisa_registration ? this.item.anvisa_registration : null,
        model: this.item.model ? this.item.model : null,
        item: this.item,
        other_expense_props: this.item.other_expense_props,
        quantity: 1,
        unit_measurement: this.item.other_expense_props.unit_measurement,
        manufacturer_reference: this.item.other_expense_props.manufacturer_reference ? this.item.other_expense_props.manufacturer_reference : null,
        unitary_value: this.item.other_expense_props.unitary_value,
        total_value: this.item.other_expense_props.total_value,
        authorization_number: this.item.other_expense_props.authorization_number ? this.item.other_expense_props.authorization_number : null,
      })
      this.item = null
    },
    copyExpense(expense) {
      this.form.expenses.push({ ...expense, id: null })
    },
    editExpense(index) {
      this.index = index
      this.expenseEdit = JSON.parse(JSON.stringify(this.form.expenses[index]))
      this.$bvModal.show('add_realized_expenses_modal')
    },
    deleteModal(expense, index) {
     this.deleteExpense(index)
    },
    async deleteExpense(index) {
      const expense = this.form.expenses[index]
      try {
        if(expense.id) {
          await this.api.deleteLayoutExpense(expense.id)
          this.$toast.success('Despesa excluída com sucesso!')
        }
        this.form.expenses.splice(index, 1)        
      } catch (error) {
        this.$toast.error('Não foi possível excluir a despesa, contate o suporte se o erro persistir.')
      }
    },
    getTotalValue() {
      const total = this.form?.expenses?.reduce((acc, curr) => {
        return acc + (curr.quantity * (curr?.unitary_value ?? 0))
      }, 0)
      return parseNumberToMoney(total)
    },
    getTableConfig(otherExpenseProps) {
      if (this.isSameMaterialMedicineTable) {
        if (['02'].includes(otherExpenseProps.cd)) {
          return this.clinicHealthPlanTableMedicine
        } else if (['03', '08'].includes(otherExpenseProps.cd)) {
          return this.clinicHealthPlanTableMaterial
        } else {
          return null
        }
      } else {
        return this.clinicHealthPlan?.clinic_health_plan_tables.find(clinicHealthPlanTable => clinicHealthPlanTable?.table_id === otherExpenseProps?.material_medicine?.table_id)
      }
    },
  },
  watch: {
    'item': {
      handler(item) {
        if (item && Object.keys(item).length) {
          const config = item.other_expense_props.material_medicine;
          const tableConfig = this.getTableConfig(item.other_expense_props)
          const exception = config?.type === 'Simpro' ? config?.simpro.exceptions[0] : config?.brasindice.exceptions[0]

          if (exception) {
            const sumInflatorDeflator = tableConfig?.increase_decrease + exception?.decrease_or_increase_factor
            const validatedValue = exception?.unitary_value ? (exception?.unitary_value * ((sumInflatorDeflator/100)+1)) : null
            this.item.unitary_value = validatedValue ?? null
          } else if (config?.type === "Brasindice") {
            const materialMedicine = config?.brasindice?.updates[0]
            this.item.unitary_value = materialMedicine?.preco_frac * ((tableConfig?.increase_decrease/100)+1)            
          } else if (config?.type === "Simpro") {
            const materialMedicine = config?.simpro?.updates[0]
            this.item.unitary_value = tableConfig?.price_formation === 'PMC' ? materialMedicine?.pc_em_ven * ((tableConfig?.increase_decrease/100)+1) : materialMedicine?.pc_em_fab * ((tableConfig?.increase_decrease/100)+1)
          } else if (!tableConfig) {            
            this.item.unitary_value = item?.unitary_value
          }
        }
      },
      immediate: true
    },
  }
}
</script>

<style lang="scss" src="@/components/Tiss/tissHealthPlan.scss" />

<style lang="scss" scoped>
.tag {
  background-color: var(--neutral-200);
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: var(--type-active);

  strong {
    color: var(--blue-500);
  }
}
.options-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .col {
    padding-left: 0;
  }
}

.button {
  margin-bottom: 19px;

}

</style>